<template>
  <div class="sys-config-page" v-loading="loading">
    <div class="sys-config-page-conten">
      <fm-title title-text="维修申请相关配置" :title-menus="[{key: 'save', label: '保存'}]" @clickTitleMenu="repairSave"></fm-title>
      <fm-form style="margin:0 2rem 0 10px;" :inline="false" label-align="left">
        <fm-form-item label="科室负责人">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="repairConfig.orgChargeUserIdList">
            <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="职能科室">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="repairConfig.busOrgIdList">
            <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="分管领导">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="repairConfig.leadUserIdList">
            <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="院长">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="repairConfig.deanUserIdList">
            <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="可选预算年份">
          <fm-input-new placeholder="请输入" v-model="repairConfig.budgetYear">
          </fm-input-new>
        </fm-form-item>
      </fm-form>
      <fm-title title-text="报废申请相关配置" :title-menus="[{key: 'save', label: '保存'}]" @clickTitleMenu="scrapSave"></fm-title>
      <fm-form style="margin:0 2rem 0 10px;" :inline="false" label-align="left">
        <fm-form-item label="科室负责人">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="scrapConfig.orgChargeUserIdList">
            <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="分管领导">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="scrapConfig.leadUserIdList">
            <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="职能科室">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="scrapConfig.busOrgIdList">
            <fm-option v-for="item in orgList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
        <fm-form-item label="分管资产领导">
          <fm-select placeholder="请选择" multiple absolute filterable v-model="scrapConfig.assetsLeadUserIdList">
            <fm-option v-for="item in userList" :key="item.key" :value="item.key" :label="item.label"></fm-option>
          </fm-select>
        </fm-form-item>
      </fm-form>
    </div>
  </div>
</template>

<script>
import {
  sysConfigRequest
} from '@/api'

export default {
  data () {
    return {
      loading: true,
      sScrapConfig: null,
      scrapConfig: {
        orgChargeUserIdList: [],
        leadUserIdList: [],
        busOrgIdList: [],
        assetsLeadUserIdList: []
      },
      sRepairConfig: null,
      repairConfig: {
        busOrgIdList: [],
        leadUserIdList: [],
        deanUserIdList: [],
        orgChargeUserIdList: [],
        budgetYear: ''
      }
    }
  },
  computed: {
    userList () {
      return this.$store.getters.userList
    },
    orgList () {
      return this.$store.getters.orgList
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      let res = await sysConfigRequest.get({type: 'sysConfig'})
      let scrapConfig = res.find(v => v.configKey === 'scrap_config')
      this.sScrapConfig = scrapConfig ? scrapConfig.value : null
      let value = this.sScrapConfig ? JSON.parse(this.sScrapConfig) : {}
      Object.keys(this.scrapConfig).forEach(key => {
        this.scrapConfig[key] = value[key] || []
      })

      let repairConfig = res.find(v => v.configKey === 'repair_config')
      this.sRepairConfig = repairConfig ? repairConfig.value : null
      value = this.sRepairConfig ? JSON.parse(this.sRepairConfig) : {}
      Object.keys(this.repairConfig).forEach(key => {
        this.repairConfig[key] = value[key] || (key === 'budgetYear' ? '' : [])
      })
      this.loading = false
    },
    async repairSave () {
      if (this.loading) {
        return
      }
      if (this.sRepairConfig === JSON.stringify(this.repairConfig)) {
        return
      }
      this.loading = true
      await sysConfigRequest.add({
        type: 'sysConfig',
        configKey: 'repair_config',
        name: '',
        remark: '',
        value: JSON.stringify(this.repairConfig)
      })
      this.loading = false
      this.loadData()
    },
    async scrapSave () {
      if (this.loading) {
        return
      }
      if (this.sScrapConfig === JSON.stringify(this.scrapConfig)) {
        return
      }
      this.loading = true
      await sysConfigRequest.add({
        type: 'sysConfig',
        configKey: 'scrap_config',
        name: '',
        remark: '',
        value: JSON.stringify(this.scrapConfig)
      })
      this.loading = false
      this.loadData()
    }
  },
  async created () {
    await this.$store.dispatch('loadUserList')
    await this.$store.dispatch('loadOrgList')
    this.loadData()
  }
}
</script>

<style scoped lang="less">
.sys-config-page {
  display: block;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .sys-config-page-conten {
    margin: 20px;
    background: #fff;
    border-radius: 8px;
    padding-bottom: 20px;
  }
}
.config-item {
  min-height: 5rem;
  border-bottom: 1px solid #eee;
}
.config-item {
  display: flex;
  min-height: 3rem;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  .c-label {
    width: 15rem;
    display: flex;
    align-items: center;
  }
  .c-remark {
    flex: 3;
    display: flex;
    align-items: center;
  }
  .c-value {
    width: 15rem;
    display: flex;
    margin-left: 2rem;
    align-items: center;
  }
}
</style>